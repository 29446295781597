import React from "react";
import './home.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import LOGGA_1 from "../pictures/LOGGA_1.png";

export class App extends React.Component {
  render() {
     return (
       <div>
            <img className="logo" src={LOGGA_1} title="Hem" />      
        </div>
  );
  
  }
}

export default App;
