import React from "react";
import './information.css';


export class Information extends React.Component {
    render() {
       return (
         
         <div className="rules">
           <p className="regler">Information till dig som vår hyresgäst</p>
            
                <ul className="lista">
                      <li>Välkommen som hyresgäst - <a className="generallink" href="https://www.fastighetsagarna.se/globalassets/broschyrer-och-faktablad/boendeinformation/valkommen_som_hyresgast_191029.pdf?bustCache=1695749403616">Ladda ner detta från Fastighetsägarna</a></li>
                      <li>Bra att veta (LAVA bestämmelser) - <a className="generallink" href="bestammelser">Läs här</a></li>
                      <li>Tips inför flytten - <a className="generallink" href="https://www.fastighetsagarna.se/globalassets/broschyrer-och-faktablad/boendeinformation/tips_infor_flytten_160203.pdf?bustCache=1693032489568">Ladda ner tips inför flytten från Fastighetsägarna</a></li>           
                </ul>  
            
        </div>
    );
    
    }
  }
  
  export default Information;
               
               
               
            