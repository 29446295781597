import React from "react";
import './kontakt.css';


export class Kontakt extends React.Component {
    render() {
       return (
         
         <div className="rules">
           <p className="regler">Några viktiga kontaktuppgifter för dig som hyresgäst:</p>
            
               <ul className="lista">
                <li>Kontakt: <a className="generallink" href="mailto:info@lavafastigheter.se">info@lavafastigheter.se</a></li>
                <li>Felanmälan: <a className="generallink" href="mailto:felanmalan@lavafastigheter.se">felanmalan@lavafastigheter.se</a></li>
                <li>Jour: <a className="generallink" href="tel:0214900833">Aros Fastighetstjänst 021-490 08 33</a></li>
                <li>Störningsjour: <a className="generallink" href="tel:0102109000">Avarn 010-210 90 00</a></li>                   
            </ul>


        </div>
    );
    
    }
  }
  
  export default Kontakt;
               
               
               
            