import React from "react";
import './App.css';
import NavBar from "./components/navBar/navBar";
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from "..//src/components/home/home";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Intresse from "..//src/components/intresse/intresse";
import Information from "..//src/components/information/information";
import Kontakt from "..//src/components/kontakt/kontakt";
import Bestammelser from "..//src/components/bestammelser/bestammelser";
import Flyttanmalan from "./components/flyttanmalan/flyttanmalan";

export class App extends React.Component {
  
  render() {
     return (
       <div className="App">  
          <header className="App-header">
            <NavBar/>
          </header>  
          <div className="container">
            <BrowserRouter>
              <Routes>
                  <Route path="/intresse" element={<Intresse/>}/>
                  <Route path="/information" element={<Information/>}/>
                  <Route path="/kontakt" element={<Kontakt/>}/>
                  <Route path="/bestammelser" element={<Bestammelser/>}/>
                  <Route path="/flyttanmalan" element={<Flyttanmalan/>}/>
                  <Route path="/" element={<Home/>}/>
              </Routes>
            </BrowserRouter>   
          </div>   
      </div>
  );
  
  }
}

export default App;
