import React from 'react';
import LOGGA_1 from "../pictures/LOGGA_1.png";
import "./navBar.css";
import {
  Collapse,
  Navbar, 
  Nav,
  NavItem,
  NavLink,
  NavbarBrand,NavbarToggler
  } from 'reactstrap';

export class NavBar extends React.Component {  
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render() {
    
      // <div>
      //   <Navbar color="" light expand="md">
      //     {/* <NavbarToggler /> */}
      //     <Collapse navbar>
      //       <Nav className="ml-auto" navbar>
      //           <NavItem>
      //               <NavLink className='word' href="/hem">Hem</NavLink>
      //           </NavItem>
      //           <NavItem>
      //               <NavLink className='word' href="mailto:info@lavafastigheter.se">Kontakt</NavLink>
      //           </NavItem>
      //            <NavItem>
      //               <NavLink className='word' href="mailto:felanmalan@lavafastigheter.se">Felanmälan</NavLink>
      //           </NavItem>
      //            <NavItem>
      //               <NavLink href="/intresse">Intresseanmälan</NavLink>
      //           </NavItem>
      //       </Nav>
      //     </Collapse>
      //   </Navbar>
        
      // </div>

      return (
        <div>
                <div className="navbar-wrapper">                    
                    <Navbar color="" light expand="md">
                    <NavbarBrand className="nonVisible" href="/"></NavbarBrand>
                        <NavbarToggler onClick={this.toggle} />
                        <Collapse isOpen={this.state.isOpen} navbar>
                        
                        <Nav className="ml-auto" navbar>
                <NavItem>
                    <NavLink className='word' href="/"><img className="menu_logo" src={LOGGA_1} title="Hem" /></NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className='word' href="/information">Information</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className='word' href="/kontakt">Viktiga kontaktuppgifter</NavLink>
                </NavItem>
                 {/* <NavItem>
                    <NavLink className='word' href="mailto:felanmalan@lavafastigheter.se">Felanmälan</NavLink>
                </NavItem> */}
                 <NavItem>
                    <NavLink href="/intresse">Intresseanmälan</NavLink>
                </NavItem>
            </Nav>                        
                        </Collapse>
                       
                        
                    </Navbar>
                    
                </div> 
            </div> 

    );
  }
}

export default NavBar;