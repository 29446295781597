import React from "react";
import './flyttanmalan.css';


export class Flyttanmalan extends React.Component {
    render() {
       return (
         
         <div className="rules">
           <p className="regler">Kom ihåg att göra flyttanmälan!</p>
           {/* Det är viktigt att du informerar oss när du flyttar in eller ut. Risken är annars att du måste betala för någon annans el-, vatten- eller värmeförbrukning. */}
        </div>
    );
    
    }
  }
  
  export default Flyttanmalan;
               
               
               
            