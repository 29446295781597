import React from "react";
import './bestammelser.css';


export class Bestammelser extends React.Component {
    render() {
       return (
         
         <div className="rules">
           <p className="regler">Bra att veta (LAVA bestämmelser)</p>
           Vänligen respektera att journumret endast skall används i nödsituation.<br/><br/>

           Tänk på att inte ha sängen intryckt mot väggen. Fläckar bildas lätt på tapeten som inte går att tvätta bort och då måste omtapetsering faktureras Er. <u>Använd sänggavel</u>!<br/><br/>

Använd gärna en matta i Er entré som Ni ställer fuktiga/snöiga skor på så att fukten inte förstör parketten om trägolv förekommer.<br/><br/>

Spika ej eller fäst märken i inner- eller ytterdörrarna.<br/><br/>

Tänk på att hålla en lämplig ljudnivå <u>dygnet runt</u>. Många arbetar natt.<br/><br/>

Balkonglådor skall sättas upp på balkongens <u>insida</u> och inte ut mot det fria. <u>Släng inte</u> ut det Ni rensat från balkongen, ej heller fimpar, tändare eller dylikt. Informera gärna Era gäster om detta!<br/><br/>

Tänd <u>aldrig</u> cigaretter eller rök inne i trapphuset! Vänligen visa respekt mot allergiker och andra som inte tycker om rök. Informera gärna Era gäster om detta.<br/><br/>

Lämna tvättstugan i det skick Ni själv önskar finna den i nästa gång Ni skall tvätta. Regler sitter utanför tvättstugan vid tvättschemat.<br/><br/>

Lämna <u>inte</u> saker, flyttlådor etc. i källar- eller vindsgångar. Dessa kommer att föras bort och Ni blir betalningsskyldig för bortforsling.<br/><br/>

Tänk på att flytta tunga möbler som Ni inte orkar bära med en matta eller filt under.<br/><br/>

Tänk på att diska Ert filter till köksfläkten.<br/><br/>

<u>Torka bort</u> vatten från spisplattorna så att de inte rostar.<br/><br/>

Borra <u>aldrig</u> i kakelplattorna i kök eller badrum! Tätskicket förstörs då och det blir vattenskada som Ni blir betalningsskyldig till.<br/><br/>

Vänligen rengör Er PAX fläkt från damm i badrummet med jämna mellanrum om Ni har en sådan.<br/><br/>

Om en kran droppar eller en toalett rinner etc. är det Er <u>skyldighet</u> som hyresgäst att meddela hyresvärden eller dennes representant ang. detta. Annars riskerar Ni skadestånd.<br/><br/>

Se till att hyran betalas i tid. Vid sen inbetalning riskerar Ni att få betala ränta och vid flera sena inbetalningar kan kontraktet upphävas. <u>Hyran skall vara bokförd på BG sista vardagen i månaden.</u><br/><br/>

Endast rest- och bioavfall får kastas i sopkärlen. Kartonger mm. måste föras till Återbruk!<br/>
Närmaste återvinningsbruk finner ni på Ängsgärdet.

<br/><br/>
Tack! 

        </div>
    );
    
    }
  }
  
  export default Bestammelser;
               
               
               
            